import { notNull } from "@warrenio/utils/notNull";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { stopVmMutation } from "./vmQuery.ts";
import type { GQVmItem } from "./VmsTable.tsx";

interface StopVmBlockModalProps {
    item: GQVmItem;
    force?: boolean;
}

export function StopVmBlockModalContent({ item, force }: StopVmBlockModalProps) {
    const stopMutation = useStandardMutation(stopVmMutation);

    const { uuid, name, _location } = item;

    async function onSubmit() {
        await stopMutation.mutateAsync({ uuid: uuid, force: force!, location: notNull(_location) });
    }

    return (
        <WModalContent
            title={force ? "Force Stop Virtual Machine" : "Stop Virtual Machine"}
            label="Start"
            autoFocus
            modalAction={async () => await onSubmit()}
        >
            {force ? (
                <p>
                    This will forcefully shut down resource "<b>{name}</b>".
                </p>
            ) : (
                <p className="pb-3">
                    This will shut down resource "<b>{name}</b>". If graceful shutdown does not finish in 120 seconds,
                    then a forceful stop will be carried out.
                </p>
            )}
        </WModalContent>
    );
}
