import { notNull } from "@warrenio/utils/notNull";
import type { ReactNode } from "react";
import { Badge } from "../../components/Badge.tsx";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { OsImageIcon } from "../../modules/compute/os/OsImageIcon.tsx";
import { useOs } from "../../modules/compute/os/useOs.ts";
import type { GQVmItem } from "./VmsTable.tsx";

export function ModalHeaderBlock({ item, children }: { item: GQVmItem; children?: ReactNode }) {
    const { name, uuid, os_name, os_version } = item;
    const { title } = useOs({ os_name: os_name!, os_version: os_version! });

    return (
        <div className="flex gap-3 items-center justify-between lightBlock">
            <OsImageIcon os_name={os_name!} className="color-primary size-2.5rem" />
            <div className="flex-grow VStack gap-0.5 children:min-h-1.5em">
                <div>
                    <b>{name}</b> - <ClipBoardTooltip>{uuid}</ClipBoardTooltip>
                </div>
                <div className="color-muted">{title}</div>
                <MachineSizeInfo item={item} />
            </div>

            {children}
        </div>
    );
}

function MachineSizeInfo({ item }: { item: GQVmItem }) {
    const { vcpu, memory } = item;

    return (
        <div className="flex gap-0.25em">
            <Badge color="text">{vcpu} vCPU</Badge>
            <Badge color="text">{memory / 1024} GB</Badge>
            <Badge color="text">{getPrimaryStorage(item).size} GB</Badge>
        </div>
    );
}

function getPrimaryStorage(item: GQVmItem) {
    return notNull(notNull(item.storage).find((item) => item.primary));
}
