import { mustGet } from "@warrenio/utils/collections/mustGet";
import { notNull } from "@warrenio/utils/notNull";
import { Suspense } from "react";
import { BadgeDot } from "../../components/Badge.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { WMeter } from "../../components/WMeter.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { adminHostQueryAtom } from "./hostQuery.ts";
import { StatusBadge } from "./StatusBadge.tsx";

export interface CurrentHostProps {
    location: string;
    value?: string;
}

export function CurrentHostCustom({ location, value }: CurrentHostProps) {
    const hosts = useSuspenseQueryAtom(adminHostQueryAtom(location));
    const host = mustGet(hosts, value, "host");

    const { name, ip, allocated_vcpu, vcpu_free, usable_vcpu_amount, allocated_vmem, vmem_free, usable_vmem_amount } =
        host;

    const cpuTooltip = (
        <div>
            <BadgeDot color="color-primary">
                vCPU allocated: <b>{allocated_vcpu.toFixed(0)}</b>
            </BadgeDot>

            <BadgeDot color="color-primary-hover-alpha">
                vCPU available: <b>{vcpu_free.toFixed(0)}</b>
            </BadgeDot>
        </div>
    );

    const ramTooltip = (
        <div>
            <BadgeDot color="color-primary">
                vRAM allocated: <b>{allocated_vmem.toFixed(0)}</b> GB
            </BadgeDot>

            <BadgeDot color="color-primary-hover-alpha">
                vRAM available: <b>{vmem_free.toFixed(0)}</b> GB
            </BadgeDot>
        </div>
    );

    const cpuPercent = (allocated_vcpu / usable_vcpu_amount) * 100;
    const ramPercent = (allocated_vmem / usable_vmem_amount) * 100;

    return (
        <div className="position-relative">
            <DetailsTable compact>
                <DetailsTableRow title="Name:">{name}</DetailsTableRow>
                <DetailsTableRow title="IP:">{ip}</DetailsTableRow>
                <DetailsTableRow title="vCPU:">
                    <WMeter block value={cpuPercent} tooltip={cpuTooltip} />
                </DetailsTableRow>
                <DetailsTableRow title="vRAM:">
                    <WMeter block value={ramPercent} tooltip={ramTooltip} />
                </DetailsTableRow>
                <DetailsTableRow title="Status:">
                    <StatusBadge item={notNull(host)} />
                </DetailsTableRow>
            </DetailsTable>

            <MaskIcon className="jp-host-icon color-primary size-2.5rem position-absolute top-0px right-0px" />
        </div>
    );
}

export function CurrentHost(props: CurrentHostProps) {
    return (
        <Suspense fallback={<Loading key="loading" delay={0} icon="none" white />}>
            <CurrentHostCustom {...props} />
        </Suspense>
    );
}
