import type { AdminVmConfBody, VmMigrateBody } from "@warrenio/api-spec/spec.oats.gen";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";

//#region Mutations

export const startVmMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "start",
    requestFn: async ({ uuid, location }: { uuid: string; location: string }) =>
        await apiClient.POST("/{location}/user-resource/admin/vm/start", {
            ...jsonEncodedBody,
            body: { uuid },
            params: { path: { location } },
        }),
}));

export const stopVmMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "stop",
    requestFn: async ({ uuid, location, force }: { uuid: string; location: string; force: boolean }) =>
        await apiClient.POST("/{location}/user-resource/admin/vm/stop", {
            ...jsonEncodedBody,
            body: { uuid, force },
            params: { path: { location } },
        }),
}));

export const deleteVmMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "delete",
    requestFn: async ({ uuid, location }: { uuid: string; location: string }) =>
        await apiClient.DELETE("/{location}/user-resource/admin/vm", {
            ...jsonEncodedBody,
            body: { uuid },
            params: { path: { location } },
        }),
}));

export const migrateVmMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "migrate",
    requestFn: async ({ body, location }: { body: VmMigrateBody; location: string }) =>
        await apiClient.POST("/{location}/base-operator/migrate", {
            ...jsonEncodedBody,
            body,
            params: { path: { location } },
        }),
}));

export const updateNicIftuneMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "update",
    requestFn: async ({ body, location }: { body: AdminVmConfBody; location: string }) =>
        await apiClient.PUT("/{location}/base-operator/admin/vm/iftune", {
            ...jsonEncodedBody,
            body,
            params: { path: { location } },
        }),
}));
//#endregion Mutations
