import { Suspense } from "react";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { adminHostPoolQueryAtom } from "./hostPoolsQuery.ts";

export interface CurrentPoolProps {
    location: string;
    value: string;
}

export function CurrentPoolCustom({ location, value }: CurrentPoolProps) {
    const pools = useSuspenseQueryAtom(adminHostPoolQueryAtom(location));

    const pool = pools.get(value);
    const { name, cpu_allocate_coefficient, mem_allocate_coefficient, cpu_model, hosts_assigned_count } = pool ?? {};

    return (
        <div className="position-relative">
            <DetailsTable compact>
                <DetailsTableRow title="Name:">{name}</DetailsTableRow>
                <DetailsTableRow title="CPU coef.:">{cpu_allocate_coefficient}</DetailsTableRow>
                <DetailsTableRow title="Mem coef.:">{mem_allocate_coefficient}</DetailsTableRow>
                <DetailsTableRow title="CPU model:">{cpu_model}</DetailsTableRow>
                <DetailsTableRow title="Host(s):">{hosts_assigned_count}</DetailsTableRow>
            </DetailsTable>

            <MaskIcon className="jp-hostpool-icon color-primary size-2.5rem position-absolute top-0px right-0px" />
        </div>
    );
}

export function CurrentPool(props: CurrentPoolProps) {
    return (
        <Suspense fallback={<Loading key="loading" delay={0} icon="none" white />}>
            <CurrentPoolCustom {...props} />
        </Suspense>
    );
}
