import type { SelectedOs } from "../../modules/compute/os/os.ts";
import { OsImageIcon } from "../../modules/compute/os/OsImageIcon.tsx";
import { useOs } from "../../modules/compute/os/useOs.ts";

export function VmOs({ value }: { value: SelectedOs }) {
    return value.os_name === "service" ? <VmServiceOs value={value} /> : <VmRegularOs value={value} />;
}

function VmServiceOs({ value }: { value: SelectedOs }) {
    const { os_version } = value;
    return (
        <div>
            <div className="HStack gap-0.25rem whitespace-nowrap">
                <OsImageIcon os_name="service" className="color-primary size-0.875rem" />
                {os_version}
            </div>
            <div className="text-muted font-size-small">Managed service</div>
        </div>
    );
}

function VmRegularOs({ value }: { value: SelectedOs }) {
    const { os_name } = value;
    const { title } = useOs(value);

    return (
        <div className="HStack gap-0.25rem whitespace-nowrap">
            <OsImageIcon os_name={os_name} className="color-primary size-0.875rem" />
            {title}
        </div>
    );
}
